<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-auto d-flex">
          <div class="circle-icon mr-2" :class="backgroundColor">
            <span>
              <img :src="require(`@/assets/${ icon }.svg`)" />
            </span>
          </div>
        </div>
        <div class="col">
          <span class="font-weight-bold mt-2">{{ title }}</span>
          <div class="row d-flex justify-content-space-between" v-for="(stat, index) in data" :key="index">
            <div class="col">{{ stat.key }}</div>
            <div class="col font-weight-bold" :class="textColor">
              <div>{{ stat.value }}</div>
            </div>
          </div>
          <div class="row d-flex justify-content-space-between" v-if="isComparable">
            <div class="col">&nbsp;</div>
            <div class="col font-weight-bold" :class="textColor">
              {{ displayCompareValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
 .card {
   min-height: 138px;
 }
</style>

<script>
export default {
  name: "dashboard-overview",
  props: {
    title: { type: String, required: true },
    color: { type: String, required: true },
    icon: { type: String, required: true },
    data: { type: Array, required: true },
    compareByCount: { type: Boolean, default: () => false },
    compareByPercentage: { type: Boolean, default: () => false },
    noCompare: { type: Boolean, default: false }
  },
  computed: {
    isComparable() {
      return (!this.noCompare && this.data.length > 1 && (this.compareByCount || this.compareByPercentage));
    },
    displayCompareValue() {
      let value = null;

      if (this.compareByCount) value = this.displayCompareCount;
      if (this.compareByPercentage) value = this.displayComparePercentage;

      if (value > 0) value = '+' + value;
      if (this.compareByPercentage) value = value + '%';

      return value;
    },
    displayCompareCount() {
      return this.data[1].value - this.data[0].value;
    },
    displayComparePercentage() {
      let prev = this.data[1].value;
      let curr = this.data[0].value;

      return Math.round((curr - prev) / prev * 100);
    },
    backgroundColor() {
      return "bg-" + this.color;
    },
    textColor() {
      return "text-" + this.color;
    }
  }
};
</script>
